import React from 'react';
import {
  ReferenceManyField,
  Datagrid,
  DateField,
  NumberField,
  TextField,
  useRecordContext,
  Pagination,
  NumberInput,
  TextInput,
  EditButton,
} from 'react-admin';
import QuickCreateButton from 'components/QuickCreateButton';

const WageLogsTab = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <div>
      <QuickCreateButton
        initialValues={{ professional_id: record.id }}
        resource="WageLog"
        title="Add Wage Log"
      >
        <NumberInput label="Hourly Rate (Cents)" source="hourly_rate_cents" fullWidth />
        <NumberInput label="Position ID" source="position_id" fullWidth />
        <NumberInput label="Work History ID" source="work_history_id" fullWidth />
        <TextInput label="Zip Code" source="zip_code" fullWidth />
      </QuickCreateButton>

      <ReferenceManyField
        reference="WageLog"
        target="professional_id"
        source="id"
        pagination={<Pagination />}
        data-testid="professional_show-wage_logs_table"
        sort={{ field: 'id', order: 'ASC' }}
      >
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="id" label="ID" />
          <NumberField source="hourly_rate_cents" label="Hourly Rate (Cents)" />
          <NumberField source="position_id" label="Position ID" />
          <NumberField source="work_history_id" label="Work History ID" />
          <TextField source="zip_code" label="Zip Code" />
          <DateField source="created_at" label="Created At" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
};

export default WageLogsTab;
