import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  FunctionField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  useRecordContext,
  Labeled,
  Link,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import LinkField from 'components/LinkField';
import TypeformShow from 'resources/professional_qualifications/TypeformShow';
import { ProfessionalQualificationStatus } from 'typings/enums';
import ProfessionalQualificationAttributeField from 'resources/professional_qualification_attributes/ProfessionalQualificationAttributeField';
import { ProfessionalAside } from 'resources/professionals/ProfessionalAside';
import IdField from 'components/IdField';
import TimestampFields from 'components/TimestampFields';
import MetabaseUrlField from 'components/MetabaseUrlField';
import { JsonField } from 'react-admin-json-view';

type CheckrMetadataFieldProps = {
  label: string;
  source: string;
};

const CheckrMetadataField = ({ label, source }: CheckrMetadataFieldProps) => {
  const record = useRecordContext();
  const isBackgroundCheck = record?.qualification?.qualification_type === 'background_check';

  if (!isBackgroundCheck) {
    return null;
  }

  if (source === 'metadata.candidate_id') {
    return (
      <Typography>
        <Link
          target="_blank"
          to={`https://dashboard.checkr.com/candidates/${record.metadata.candidate_id}`}
        >
          {record.metadata.candidate_id}
        </Link>
      </Typography>
    );
  }

  return <TextField label={label} source={source} />;
};

const AgencyQualificationShow = () => (
  <Show aside={<ProfessionalAside />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <LinkField label="Agency" source="agency_id" />
      <TextField label="Agency Name" source="agency.name" />
      <LinkField label="Qualification ID" source="qualification_id" />
      <TextField label="Qualification Name" source="qualification.long_name" />
      <TextField source="status" />
      <FunctionField
        label="Rejected Reason"
        render={(record) =>
          record.status === ProfessionalQualificationStatus.rejected ? (
            <ReferenceField
              source="credential_rejected_reason_id"
              reference="CredentialRejectedReason"
            >
              <TextField source="label" />
            </ReferenceField>
          ) : (
            'N/A'
          )
        }
      />
      <TimestampFields />
      <Labeled label="Last Updated By">
        <FunctionField
          render={(record) =>
            record?.last_updated_by_admin_id ? (
              <Link to={`/Administrator/${record.last_updated_by_admin_id}/show`}>
                {record.last_updated_by_admin?.name ?? record.last_updated_by_admin_id}
              </Link>
            ) : (
              'N/A'
            )
          }
        />
      </Labeled>

      <MetabaseUrlField />

      <CheckrMetadataField label="Checkr Report ID" source="metadata.report_id" />
      <CheckrMetadataField label="Checkr Report Status" source="metadata.report_status" />
      <CheckrMetadataField label="Checkr Candidate ID" source="metadata.candidate_id" />
      <CheckrMetadataField label="Notes" source="metadata.notes" />
      <JsonField label="Metadata" source="metadata" />

      <Box mt={4}>
        <Typography>Qualification Attributes</Typography>
      </Box>
      <FunctionField
        render={(record) =>
          record?.qualification?.qualification_type === 'typeform' ? (
            <TypeformShow record={record} />
          ) : (
            <ReferenceManyField
              label="Qualification Attributes"
              target="agency_qualification_id"
              reference="AgencyQualificationAttribute"
              filter={{
                search: {
                  qa_active: true,
                  qa_input_sources: ['admin', 'user', 'system', 'agency'],
                },
              }}
            >
              <Datagrid bulkActionButtons={false}>
                <IdField />
                <TextField label="Name" source="qualification_attribute.name" />
                <FunctionField
                  label="Attribute Type"
                  render={(record) => record.type.split('::')[1]}
                />
                <ProfessionalQualificationAttributeField />
              </Datagrid>
            </ReferenceManyField>
          )
        }
      />
    </SimpleShowLayout>
  </Show>
);

AgencyQualificationShow.query = gql`
  query GET_ONE($id: Int!) {
    agencyQualification(id: $id) {
      id
      created_at
      updated_at
      status
      metadata
      agency_id
      agency {
        id
        name
      }
      agency_qualification_attributes {
        id
        type
        value
        qualification_attribute_id
        qualification_attribute {
          id
          slug
          field_type
          name
          required
        }
      }
      last_updated_by_admin_id
      last_updated_by_admin {
        id
        name
      }
      credential_rejected_reason_id
      qualification_id
      qualification {
        id
        long_name
        qualification_type
      }
    }
  }
`;

export default AgencyQualificationShow;
