import React from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  Tab,
  TextField,
  BooleanField,
  NumberField,
  TopToolbar,
  EditButton,
  Pagination,
  Datagrid,
  ReferenceManyField,
  DateField,
  useRecordContext,
} from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';
import JobBillingsTab from 'components/tabs/JobBillingsTab';
import ExpenseAdjustmentsTab from 'components/tabs/ExpenseAdjustmentsTab';
import TimecardsTab from 'components/tabs/TimecardsTab';
import { ENUMS } from 'components/Enum/enums';
import PayW2Button from './PayW2Button';
import ApplyStaffBalance from './ApplyStaffBalance';
import useCheckAccess from 'hooks/useCheckAccess';
import ReversalButton from './ReversalButton';

const PayoutShowActions = () => {
  const checkAccess = useCheckAccess();
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const { status, kind } = ENUMS.Payout;
  const w2Payable = (payout) => {
    if (payout.status === status.approved && payout.kind === kind.w2) {
      return true;
    }
    return false;
  };
  const canApplyBalance: boolean =
    [status.open, status.paid, status.approved].includes(record.status) &&
    record.amount_cents > 0 &&
    record.staff?.balance_cents > 0;

  const canReversePayout: boolean =
    record.status === 'failed' && record.stripe_transfer_id !== null;

  return (
    <TopToolbar sx={{ justifyContent: 'flex-start' }}>
      {canApplyBalance && (
        <ApplyStaffBalance amount={record.amount_cents} balance={record.staff?.balance_cents} />
      )}
      {w2Payable(record) && <PayW2Button />}
      <EditButton sx={{ marginLeft: 'auto' }} />
      {checkAccess('actions', 'Payout', 'reverse') && canReversePayout && <ReversalButton />}
    </TopToolbar>
  );
};

const PayoutsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<PayoutShowActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Info">
          {checkAccess('view', 'Payout', 'id') && <TextField label="ID" source="id" />}
          {checkAccess('view', 'Payout', 'amount_cents') && (
            <MoneyField label="Amount Cents" source="amount_cents" />
          )}
          {checkAccess('view', 'Payout', 'amount_currency') && (
            <TextField label="Amount Currency" source="amount_currency" />
          )}
          {checkAccess('view', 'Payout', 'starts_time') && (
            <DateTimeField
              label="Starts Time"
              source="starts_time"
              timeZone="America/Los_Angeles"
            />
          )}
          {checkAccess('view', 'Payout', 'ends_time') && (
            <DateTimeField label="Ends Time" source="ends_time" timeZone="America/Los_Angeles" />
          )}
          {checkAccess('view', 'Payout', 'w2_submit_by_time') && (
            <DateTimeField
              label="W2 Submit by Time"
              source="w2_submit_by_time"
              timeZone="America/Los_Angeles"
            />
          )}
          {checkAccess('view', 'Payout', 'pay_by_date') && (
            <DateTimeField
              label="Pay by Date"
              source="pay_by_date"
              timeZone="America/Los_Angeles"
            />
          )}
          {checkAccess('view', 'Payout', 'arrival_date') && (
            <DateTimeField
              label="Arrival Date"
              source="arrival_date"
              timeZone="America/Los_Angeles"
            />
          )}
          {checkAccess('view', 'Payout', 'status') && <TextField source="status" />}
          {checkAccess('view', 'Payout', 'account_type') && (
            <TextField label="Account Type" source="account_type" />
          )}
          {checkAccess('view', 'Professional', 'id') && (
            <LinkField label="Professional ID" source="professional.id" />
          )}
          {checkAccess('view', 'Assignment', 'id') && (
            <LinkField label="Assignment ID" source="assignment.id" />
          )}
          {checkAccess('view', 'Payout', 'stripe_id') && (
            <TextField label="Stripe ID" source="stripe_id" />
          )}
          {checkAccess('view', 'Payout', 'stripe_transfer_id') && (
            <TextField label="Stripe Transfer ID" source="stripe_transfer_id" />
          )}
          {checkAccess('view', 'Payout', 'pause') && <BooleanField label="Pause" source="pause" />}
          {checkAccess('view', 'Professional', 'balance_cents') && (
            <MoneyField label="Professional Balance Cents" source="professional.balance_cents" />
          )}
          {checkAccess('view', 'Payout', 'source_type') && (
            <TextField label="Source Type" source="source_type" />
          )}
          {checkAccess('view', 'Payout', 'notes') && <TextField label="Notes" source="notes" />}
          {checkAccess('view', 'Payout', 'adp_id') && <TextField label="ADP ID" source="adp_id" />}
          {checkAccess('view', 'Payout', 'processed_on') && (
            <DateTimeField
              label="Processed On"
              source="processed_on"
              timeZone="America/Los_Angeles"
            />
          )}
          {checkAccess('view', 'Payout', 'failed_count') && (
            <NumberField label="Failed Count" source="failed_count" />
          )}
          {checkAccess('view', 'Payout', 'failed_reason') && (
            <TextField label="Failed Reason" source="failed_reason" />
          )}
          <TimestampFields />
        </Tab>
        <Tab label="Expense Adjustments" path="expense-adjustments">
          {checkAccess('view', 'ExpenseAdjustment', 'payout_id') && (
            <ExpenseAdjustmentsTab target="payout_id" />
          )}
        </Tab>
        <Tab label="Job Billings" path="job-billings">
          {checkAccess('view', 'JobBilling', 'payout_id') && <JobBillingsTab target="payout_id" />}
        </Tab>
        <Tab label="Payments" path="payments">
          {checkAccess('view', 'Payment', 'payout_id') && (
            <ReferenceManyField
              pagination={<Pagination />}
              label=""
              reference="Payment"
              target="payout_id"
            >
              <Datagrid bulkActionButtons={false}>
                {checkAccess('view', 'Payment', 'id') && <LinkField label="ID" source="id" />}
                {checkAccess('view', 'Payment', 'payout_id') && (
                  <LinkField label="Payout ID" source="payout_id" />
                )}
                {checkAccess('view', 'Payment', 'status') && (
                  <TextField label="Status" source="status" />
                )}
                {checkAccess('view', 'Payment', 'amount_cents') && (
                  <MoneyField label="Amount Cents" source="amount_cents" />
                )}
                {checkAccess('view', 'Payment', 'last_payment_attempt_at') && (
                  <DateField
                    label="Last Payment Attempt At"
                    source="last_payment_attempt_at"
                    showTime
                    options={{ hour12: false }}
                  />
                )}
                {checkAccess('view', 'Payment', 'processed_on') && (
                  <DateField
                    label="Processed On"
                    source="processed_on"
                    showTime
                    options={{ hour12: false }}
                  />
                )}
                {checkAccess('view', 'Payment', 'arrival_date') && (
                  <DateField
                    label="Arrival Date"
                    source="arrival_date"
                    showTime
                    options={{ hour12: false }}
                  />
                )}
                {checkAccess('view', 'Payment', 'provider') && (
                  <TextField label="Provider" source="provider" />
                )}
                {checkAccess('view', 'Payment', 'provider_transaction_id') && (
                  <TextField label="Provider Transaction ID" source="provider_transaction_id" />
                )}
                {checkAccess('view', 'Payment', 'last_error_message') && (
                  <TextField label="Last Error Message" source="last_error_message" />
                )}
                {checkAccess('view', 'Payment', 'failed_count') && (
                  <NumberField label="Failed Count" source="failed_count" />
                )}
              </Datagrid>
            </ReferenceManyField>
          )}
        </Tab>
        <Tab label="Timecards" path="timecards">
          {checkAccess('view', 'Timecard', 'id') && <TimecardsTab />}
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

PayoutsShow.query = gql`
  query GET_ONE($id: Int!) {
    payout(id: $id) {
      id
      adp_id
      kind
      amount_cents
      amount_currency
      arrival_date
      status
      account_type
      professional {
        id
        balance_cents
      }
      starts_time
      ends_time
      w2_submit_by_time
      pay_by_date
      stripe_id
      pause
      stripe_transfer_id
      source_type
      notes
      processed_on
      failed_count
      failed_reason
      assignment {
        id
        primary_location {
          id
          timezone_lookup
        }
      }
      timecard_ids
      created_at
      updated_at
    }
  }
`;

export default PayoutsShow;
