import React, { useState } from 'react';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { Typography } from '@mui/material';
import ButtonWithConfirmation from 'components/ButtonWithConfirmation';
import useApi from 'hooks/useApi';
import config from 'config';
import useCheckAccess from 'hooks/useCheckAccess';

const BG_QUALIFICATION_ID = 499;
const RE_AUTH_PAGE_LINK = `${config.client_url.professional}/onboarding/background_check/disclosure`;

const ReAuthorizeCheckr = () => {
  const record = useRecordContext();
  const api = useApi();
  const refresh = useRefresh();
  const notify = useNotify();
  const checkAccess = useCheckAccess();
  const [isLoading, setIsLoading] = useState(false);
  const canAccess = checkAccess('actions', 'ProfessionalQualification', 're_authorize_checkr');
  const qualifications = [...(record?.background_check_pqs ?? [])].sort(
    (pqA, pqB) => pqB.id - pqA.id,
  );

  const hasApproved = qualifications.some((pq) => pq.status === 'approved');
  const qualification = qualifications.find(
    (pq) => pq.qualification_id === BG_QUALIFICATION_ID && pq.status !== 'approved',
  );

  if (!record || !qualification || !canAccess || hasApproved) {
    return null;
  }

  const handleReAuthorize = async () => {
    try {
      setIsLoading(true);

      await api({
        url: `/v3/admin/professional_qualifications/${qualification.id}`,
        method: 'PUT',
        data: {
          metadata: { candidate_id: null },
        },
      });

      notify(
        `Checkr re-uthorization is done. You can now refer professional to ${RE_AUTH_PAGE_LINK}`,
        { type: 'success' },
      );
      refresh();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      if (error instanceof Error) {
        notify(error.message, { type: 'error' });
      }

      throw error;
    }
  };

  return (
    <ButtonWithConfirmation
      size="small"
      variant="outlined"
      color="primary"
      confirmationTitle="Re-Authorize Checkr"
      onClick={handleReAuthorize}
      loading={isLoading}
      confirmationContent={
        <Typography>
          Checkr candidate id will be cleared. Please confirm you want to proceed.
        </Typography>
      }
    >
      Re-Authorize Checkr
    </ButtonWithConfirmation>
  );
};

export default ReAuthorizeCheckr;
