import { gql } from '@apollo/client';
import TimestampFields from 'components/TimestampFields';
import React from 'react';
import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  EditButton,
} from 'react-admin';

const WageLogsShowActions = () => (
  <TopToolbar>
    <EditButton />
  </TopToolbar>
);

const WageLogsShow = () => (
  <Show actions={<WageLogsShowActions />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <NumberField label="Hourly Rate (Cents)" source="hourly_rate_cents" />
      <NumberField label="Position ID" source="position_id" />
      <NumberField label="Work History ID" source="work_history_id" />
      <TextField label="Zip Code" source="zip_code" />
      <TextField label="Professional ID" source="professional_id" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

WageLogsShow.query = gql`
  query GET_ONE($id: Int!) {
    wageLog(id: $id) {
      id
      professional_id
      hourly_rate_cents
      position_id
      work_history_id
      zip_code
      created_at
      updated_at
    }
  }
`;

export default WageLogsShow;
