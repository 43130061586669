import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  FunctionField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  EditButton,
  useRecordContext,
  TopToolbar,
  Link,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import LinkField from 'components/LinkField';
import TypeformShow from './TypeformShow';
import { ProfessionalQualificationStatus } from 'typings/enums';
import ProfessionalQualificationAttributeField from 'resources/professional_qualification_attributes/ProfessionalQualificationAttributeField';
import PdfButton from 'components/PdfButton';
import { ProfessionalAside } from 'resources/professionals/ProfessionalAside';
import IdField from 'components/IdField';
import TimestampFields from 'components/TimestampFields';
import MetabaseUrlField from 'components/MetabaseUrlField';
import LastUpdatedByAdminField from './LastUpdatedByAdminField';
import { JsonField } from 'react-admin-json-view';

type CheckrMetadataFieldProps = {
  label: string;
  source: string;
};

const CheckrMetadataField = ({ label, source }: CheckrMetadataFieldProps) => {
  const record = useRecordContext();
  const isBackgroundCheck = record?.qualification?.qualification_type === 'background_check';

  if (!isBackgroundCheck) {
    return null;
  }

  if (source === 'metadata.candidate_id' && record?.metadata?.candidate_id) {
    return (
      <Typography>
        <Link
          target="_blank"
          to={`https://dashboard.checkr.com/candidates/${record?.metadata?.candidate_id}`}
        >
          {record?.metadata?.candidate_id}
        </Link>
      </Typography>
    );
  }

  return <TextField label={label} source={source} />;
};

const QualificationActions = () => {
  const record = useRecordContext();
  return record ? (
    <TopToolbar>
      <PdfButton
        variant="outlined"
        color="primary"
        download={`${record?.professional?.account?.name} Qualifications.pdf`}
        href={`/professional_qualifications/${record.id}`}
      >
        Download PDF
      </PdfButton>
      <EditButton />
    </TopToolbar>
  ) : null;
};

const QualificationShow = () => (
  <Show aside={<ProfessionalAside />} actions={<QualificationActions />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <LinkField label="Professional" source="professional_id" />
      <TextField label="Professional Name" source="professional.account.name" />
      <LinkField label="Qualification ID" source="qualification_id" />
      <TextField label="Qualification Name" source="qualification.long_name" />
      <TextField source="status" />
      <FunctionField
        label="Rejected Reason"
        render={(record) =>
          record.status === ProfessionalQualificationStatus.rejected ? (
            <ReferenceField
              source="credential_rejected_reason_id"
              reference="CredentialRejectedReason"
            >
              <TextField source="label" />
            </ReferenceField>
          ) : (
            'N/A'
          )
        }
      />
      <FunctionField
        label="Additional Rejection Context"
        render={(record) =>
          record.status === ProfessionalQualificationStatus.rejected ? (
            <TextField source="additional_rejection_context" />
          ) : (
            'N/A'
          )
        }
      />
      <TimestampFields />
      <LastUpdatedByAdminField />

      <MetabaseUrlField />

      <CheckrMetadataField label="Checkr Report ID" source="metadata.report_id" />
      <CheckrMetadataField label="Checkr Report Status" source="metadata.report_status" />
      <CheckrMetadataField label="Checkr Candidate ID" source="metadata.candidate_id" />
      <CheckrMetadataField label="Notes" source="metadata.notes" />
      <JsonField label="Metadata" source="metadata" />

      <Box mt={4}>
        <Typography>Qualification Attributes</Typography>
      </Box>
      <FunctionField
        render={(record) =>
          record?.qualification?.qualification_type === 'typeform' ? (
            <TypeformShow record={record} />
          ) : (
            <ReferenceManyField
              label="Qualification Attributes"
              target="professional_qualification_id"
              reference="ProfessionalQualificationAttribute"
              filter={{
                search: {
                  qa_active: true,
                  qa_input_sources: ['admin', 'user', 'system', 'agency'],
                },
              }}
            >
              <Datagrid bulkActionButtons={false}>
                <IdField />
                <TextField label="Name" source="qualification_attribute.name" />
                <FunctionField
                  label="Attribute Type"
                  render={(record) => record.type.split('::')[1]}
                />
                <ProfessionalQualificationAttributeField />
              </Datagrid>
            </ReferenceManyField>
          )
        }
      />
    </SimpleShowLayout>
  </Show>
);

QualificationShow.query = gql`
  query GET_ONE($id: Int!) {
    professionalQualification(id: $id) {
      id
      created_at
      updated_at
      status
      metadata
      professional_id
      professional {
        id
        markets {
          id
          display_name
        }
        positions {
          id
          display_name
        }
        specialties {
          id
          label
        }
        account {
          id
          first_name
          last_name
          name
        }
        balance_cents
      }
      admin_professional_qualification_attributes {
        id
        type
        value
        qualification_attribute_id
        qualification_attribute {
          id
          slug
          field_type
          name
          required
          data
        }
      }
      last_updated_by_admin_id
      last_updated_by_admin {
        id
        name
      }
      credential_rejected_reason_id
      additional_rejection_context
      qualification_id
      qualification {
        id
        long_name
        qualification_type
      }
    }
  }
`;

export default QualificationShow;
