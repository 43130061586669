import React from 'react';
import { useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { Link } from '@mui/material';

export type GoogleMapsLinkFieldProps = {
  sources?: {
    latitude?: string;
    longitude?: string;
    address_first?: string;
    address_city?: string;
    state?: string;
  };
};

export const GoogleMapsLinkField = (props: GoogleMapsLinkFieldProps) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const { sources = {} } = props;
  const latKey = sources.latitude || 'coordinates.latitude';
  const longKey = sources.longitude || 'coordinates.longitude';
  const addressKey = sources.address_first || 'address_first';
  const cityKey = sources.address_city || 'address_city';
  const stateKey = sources.state || 'state.name';

  const latitude = get(record, latKey);
  const longitude = get(record, longKey);
  const address = get(record, addressKey)?.replace(/ /g, '+');
  const city = get(record, cityKey)?.replace(/ /g, '+');
  const stateName = get(record, stateKey)?.replace(/ /g, '+');

  if (!latitude || !longitude || !address || !city || !stateName) {
    return null;
  }

  const url = `https://www.google.com/maps/dir/${latitude},+${longitude}/${address},${city},${stateName}`;

  return (
    <Link
      href={url}
      variant="body2"
      target="_blank"
      rel="noopener"
      style={{ textDecoration: 'none' }}
    >
      Google Maps
    </Link>
  );
};

export default GoogleMapsLinkField;
