import { gql } from '@apollo/client';
import IdField from 'components/IdField';
import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  TopToolbar,
  CreateButton,
} from 'react-admin';

const WageLogsListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const WageLogsList = () => (
  <List actions={<WageLogsListActions />} exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <NumberField label="Hourly Rate (Cents)" source="hourly_rate_cents" />
      <NumberField label="Position ID" source="position_id" />
      <NumberField label="Work History ID" source="work_history_id" />
      <TextField label="Zip Code" source="zip_code" />
      <TextField label="Professional ID" source="professional_id" />
      <DateField label="Created At" source="created_at" />
    </Datagrid>
  </List>
);

WageLogsList.query = gql`
  query GET_LIST($input: WageLogQueryInput) {
    _wageLogsMeta(input: $input) {
      count
    }
    wageLogs(input: $input) {
      id
      professional_id
      hourly_rate_cents
      position_id
      work_history_id
      zip_code
      created_at
      updated_at
    }
  }
`;

export default WageLogsList;
