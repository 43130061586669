import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  AutocompleteArrayInput,
  DateTimeInput,
  FormDataConsumer,
  SelectArrayInput,
} from 'react-admin';
import { choicesFromEnum, choicesForProKinds } from 'utils/helpers';
import { ENUMS } from 'components/Enum/enums';
import { travel_email_preferences } from 'typings/enums';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import AdministratorInput from 'components/Filter/AdministratorInput';
import DateInput from 'components/DateInput';
import useCheckAccess from 'hooks/useCheckAccess';
import ReferralSourceInput from 'components/ReferralSourceInput';

/**
 * editing these directly is still required for a few workflows from the admin teams
 * we anticipate removing this ability soon to force application status changes to go
 * through the action buttons/the ApplicationStatusControl
 */
enum ApplicationStatus {
  'Approved' = 'approved',
  'Archived' = 'archived',
  'Phone Screen Pending' = 'phone_screen_pending',
  'Phone Screen Scheduled' = 'phone_screen_scheduled',
  'Rejected' = 'rejected',
  'Resume Review' = 'resume_review',
}

const paylocityStatusChoices = [
  { id: 'active', name: 'Active' },
  { id: 'terminated', name: 'Terminated' },
  { id: 'not_in_paylocity', name: 'Not in Paylocity' },
];

const ProfessionalsEdit = () => {
  const checkAccess = useCheckAccess();
  return (
    <Edit mutationMode="pessimistic" data-testid="staff-edit" redirect="show">
      <SimpleForm>
        {checkAccess('update', 'Professional', 'w2_enabled') && (
          <BooleanInput source="w2_enabled" data-testid="professional_edit-w2_enabled_field" />
        )}
        {checkAccess('update', 'Professional', 'nickname') && (
          <TextInput
            label="Nickname"
            source="nickname"
            data-testid="professional_edit-nickname_field"
          />
        )}
        {checkAccess('update', 'Professional', 'dob') && (
          <DateInput label="Date of Birth" source="dob" />
        )}
        {checkAccess('update', 'Professional', 'application_status') && (
          <SelectInput
            label="Application Status"
            source="application_status"
            choices={choicesFromEnum(ApplicationStatus)}
            data-testid="professional_edit-application_status_field"
          />
        )}
        {checkAccess('update', 'Professional', 'kinds') && (
          <SelectArrayInput
            label="Kind"
            source="kinds"
            choices={choicesForProKinds(ENUMS.Professional.kind)}
            data-testid="professional_edit-kinds_field"
          />
        )}
        {checkAccess('update', 'Professional', 'paylocity_external_id') && (
          <TextInput
            label="Paylocity ID"
            source="paylocity_external_id"
            data-testid="professional_edit-paylocity_id_field"
          />
        )}
        {checkAccess('update', 'Professional', 'paylocity_status') && (
          <SelectInput
            label="Paylocity Status"
            source="paylocity_status"
            choices={paylocityStatusChoices}
            data-testid="professional_edit-paylocity_status_field"
          />
        )}
        <ReferenceArrayInput
          label="Markets"
          reference="Market"
          source="market_ids"
          sort={{ field: 'display_name', order: 'ASC' }}
        >
          <AutocompleteArrayInput
            optionText="display_name"
            data-testid="professional_edit-market_field"
          />
        </ReferenceArrayInput>
        {checkAccess('update', 'Professional', 'drug_screening_completed') && (
          <BooleanInput
            label="Drug Screening Completed"
            source="drug_screening_completed"
            data-testid="professional_edit-drug_screening_completed_field"
          />
        )}
        {checkAccess('update', 'Professional', 'travel_email_notification') && (
          <BooleanInput
            label="Travel Email Notification"
            source="travel_email_notification"
            data-testid="professional_edit-travel_email_notification_field"
          />
        )}
        {checkAccess('update', 'Professional', 'travel_email_preferences') && (
          <SelectInput
            label="Travel Email Preferences"
            source="travel_email_preferences"
            choices={choicesFromEnum(travel_email_preferences)}
            data-testid="professional_edit-travel_email_preferences_field"
          />
        )}
        {checkAccess('update', 'Professional', 'detailed_notes') && (
          <TextInput
            label="Detailed Notes"
            source="detailed_notes"
            data-testid="professional_edit-detailed_notes_field"
          />
        )}
        {checkAccess('update', 'Professional', 'phone_screen_date_and_time') && (
          <DateTimeInput
            label="Phone Screen Date And Time"
            source="phone_screen_date_and_time"
            data-testid="professional_edit-phone_screen_date_and_time_field"
          />
        )}
        {checkAccess('update', 'Professional', 'screening_recruiter_id') && (
          <AdministratorInput
            source="screening_recruiter_id"
            label="Screening Recruiter"
            filter={{
              permission_group_roles: [ENUMS.PermissionGroup.role.support_agent],
            }}
            emptyText="Clear"
            data-testid="professional_edit-screening_recruiter_field"
          />
        )}
        {checkAccess('update', 'Professional', 'calendly_invitee_uuid') && (
          <TextInput
            source="calendly_invitee_uuid"
            data-testid="professional_edit-calendly_invitee_uuid_field"
          />
        )}
        {checkAccess('update', 'Professional', 'quick_note') && (
          <TextInput source="quick_note" data-testid="professional_edit-quick_note_field" />
        )}
        {checkAccess('update', 'Professional', 'recruiter_id') && (
          <AdministratorInput
            source="recruiter_id"
            label="Recruiter"
            filter={{
              permission_group_roles: [ENUMS.PermissionGroup.role.recruiter],
            }}
            emptyText="Clear"
            data-testid="professional_edit-recruiter_field"
          />
        )}
        {checkAccess('update', 'Professional', 'background_check_authorized') && (
          <BooleanInput
            label="Background Check Authorized"
            source="background_check_authorized"
            data-testid="professional_edit-background_check_authorized_field"
          />
        )}
        {checkAccess('update', 'Professional') && (
          <ReferenceArrayInput
            label="Travel States"
            source="travel_state_ids"
            reference="State"
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteArrayInput
              optionText="name"
              data-testid="professional_edit-travel_state_field"
            />
          </ReferenceArrayInput>
        )}
        {checkAccess('update', 'Professional', 'w2_eligible') && (
          <BooleanInput
            label="W2 Eligible"
            source="w2_eligible"
            data-testid="professional_show-w2_eligible_field"
          />
        )}
        {checkAccess('update', 'Professional', 'w2_onboarding_complete') && (
          <BooleanInput
            label="W2 Onboarding Complete"
            source="w2_onboarding_complete"
            data-testid="professional_edit-w2_onboarding_complete_field"
          />
        )}
        {checkAccess('update', 'Professional', 'stripe_external_account_id') && (
          <TextInput
            label="Stripe ID"
            source="stripe_external_account_id"
            data-testid="professional_edit-stripe_id_field"
          />
        )}
        {checkAccess('update', 'Professional', 'last_updated_preferred_hours_at') && (
          <DateTimeInput
            label="Last Updated Preferred Hours At"
            source="last_updated_preferred_hours_at"
            data-testid="professional_edit-last_updated_preferred_hours_at_field"
          />
        )}
        {checkAccess('update', 'Professional', 'hours_need_updating') && (
          <BooleanInput
            label="Hours Need Updating"
            source="hours_need_updating"
            data-testid="professional_edit-hours_need_updating_field"
          />
        )}
        {checkAccess('update', 'Professional', 'welcome_box_sent') && (
          <BooleanInput
            label="Welcome Box Sent"
            source="welcome_box_sent"
            data-testid="professional_edit-welcome_box_sent_field"
          />
        )}
        {checkAccess('update', 'Professional', 'referral_source') && (
          <ReferralSourceInput filter={{ applies_to_professionals: true }} />
        )}
        {checkAccess('update', 'Professional', 'other_referral_source') && (
          <FormDataConsumer>
            {({ formData }) =>
              formData.referral_source_id === 9 && (
                <TextInput label="Other Referral Source" source="other_referral_source" />
              )
            }
          </FormDataConsumer>
        )}
      </SimpleForm>
    </Edit>
  );
};

export default ProfessionalsEdit;
