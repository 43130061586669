import { gql } from '@apollo/client';
import create from './Create';
import edit from './Edit';
import list from './List';
import show from './Show';

export default {
  name: 'wage_log',
  inputShow: gql`
    query wagelogs($input: WageLogQueryInput) {
      wageLogs(input: $input) {
        id
        __typename
      }
    }
  `,
  list,
  show,
  create,
  edit,
  options: {
    group: 'Misc',
    label: 'Wage Logs',
  },
};
