import ProfessionalInput from 'components/ProfessionalInput';
import React from 'react';
import { Create, NumberInput, SimpleForm, TextInput } from 'react-admin';

const WageLogsCreate = () => (
  <Create
    resource="wagelog"
    redirect={(_resource, _id, data) => `Professional/${data.professional_id}/show/wage_logs`}
  >
    <SimpleForm>
      <ProfessionalInput />
      <NumberInput label="Hourly Rate (Cents)" source="hourly_rate_cents" />
      <NumberInput label="Position ID" source="position_id" />
      <NumberInput label="Work History ID" source="work_history_id" />
      <TextInput label="Zip Code" source="zip_code" />
    </SimpleForm>
  </Create>
);
export default WageLogsCreate;
