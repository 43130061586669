import { useGetList } from 'react-admin';
import { ProfessionalQualificationStatus } from 'typings/enums';
import { FilterEnum } from './CredentialsFilter';

// Note: Pq = ProfessionalQualification

const useProsQualifications = (professionalId: number, filterForRequired = false) => {
  const additionalFilters = filterForRequired
    ? {
        qualification_positions: {
          for_application: false,
          for_on_boarding: true,
        },
      }
    : {};

  const { data: proQualifications } = useGetList('ProfessionalQualification', {
    filter: {
      professional_id: professionalId,
      search: {
        qualification_types: [
          'q_and_a',
          'license',
          'certification',
          'document',
          'facility_onboarding',
          'signed_document',
          'background_check',
        ],
        ...additionalFilters,
      },
    },
    pagination: { page: 1, perPage: 1000 },
  });

  return (proQualifications ?? []).map((pq) => ({
    ...pq.qualification,
    ...pq,
  }));
};

const isPqArchived = ({ status }: any) => status === ProfessionalQualificationStatus.archived;

const useProsConditions = (professionalId: number) => {
  const { data: conditionData } = useGetList('Condition', {
    filter: {
      search: {
        condition_types: [
          'license',
          'certification',
          'document',
          'facility_onboarding',
          'signed_document',
          'background_check',
          'work_history',
        ],
        missing_conditions: {
          for_application: false,
          for_on_boarding: false,
          professional_id: professionalId,
        },
      },
    },
  });

  return conditionData ? Object.values(conditionData) : [];
};

type UseCredentialsEnum = Exclude<FilterEnum, FilterEnum.SATISFIED_CONDITIONS>;
type UseCredentialsReturn = Record<UseCredentialsEnum, unknown[]>;

const useCredentials = (professionalId): UseCredentialsReturn => {
  const conditions = useProsConditions(professionalId);
  const allPqs = useProsQualifications(professionalId);
  const approvedRequiredPqs = useProsQualifications(professionalId, true).filter(
    (pq) => !isPqArchived(pq),
  );

  const notArchivedPqs = allPqs.filter((pq) => !isPqArchived(pq));
  const archivedPqs = allPqs.filter(isPqArchived);

  const missingConditions: any[] = conditions.map((condition) => ({
    long_name: condition.label,
    qualification_type: condition.condition_type,
  }));
  const requiredPqIds = approvedRequiredPqs.map(({ id }) => id);
  const optionalPqs = notArchivedPqs.filter(({ id }) => !requiredPqIds.includes(id));

  return {
    [FilterEnum.ALL]: allPqs,
    [FilterEnum.MEDELY_REQUIRED]: [...approvedRequiredPqs, ...missingConditions],
    [FilterEnum.OPTIONAL]: optionalPqs,
    [FilterEnum.ARCHIVED]: archivedPqs,
  };
};

export default useCredentials;
