import React from 'react';
import {
  SimpleForm,
  BooleanInput,
  NumberInput,
  TextInput,
  AutocompleteInput,
  Edit,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  SelectInput,
  required,
} from 'react-admin';
import ReferenceInput from 'components/ReferenceInput';
import AccountManagerInput from 'components/Filter/AccountManagerInput';
import SalesAssociateInput from 'components/Filter/SalesAssociateInput';
import ClinicalAccountManagerInput from 'components/Filter/ClinicalAccountManagerInput';
import MoneyInput from 'components/MoneyInput';
import DateTimeInput from 'components/DateTimeInput';
import RestrictedInput from 'components/RestrictedInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import StateInput from 'components/StateInput';
import MarketInput from 'components/MarketInput';
import CompanyInput from 'components/CompanyInput';
import isFinite from 'lodash/isFinite';

const transformPayload = (payload) => ({
  ...payload,
  parking_cost_cents: payload.parking_free ? 0 : payload.parking_cost_cents,
});

const LocationEdit = () => (
  <Edit
    mutationMode="pessimistic"
    data-testid="locations-edit"
    redirect="show"
    transform={transformPayload}
  >
    <SimpleForm sanitizeEmptyValues>
      <CompanyInput disabled />
      <FormDataConsumer>
        {({ formData }) => {
          const hasCompany = !!formData.company_id;
          return (
            <ReferenceArrayInput
              source="billing_group_ids"
              reference="BillingGroup"
              filter={{ company_id: formData.company_id, status: 'active' }}
              disabled={!hasCompany}
              enableGetChoices={() => hasCompany}
            >
              <AutocompleteArrayInput
                optionText="name"
                disabled={!hasCompany}
                sx={{ minWidth: 256 }}
              />
            </ReferenceArrayInput>
          );
        }}
      </FormDataConsumer>
      <SelectInput
        label="Status"
        source="status"
        choices={[
          { id: 'active', name: 'Active' },
          { id: 'inactive', name: 'Inactive' },
        ]}
      />
      <TextInput
        label="Name"
        source="name"
        validate={[required()]}
        data-testid="locations-edit-name"
      />
      <TextInput label="Unit" source="unit" />
      <ReferenceInput reference="LocationType" source="location_type_id" emptyText="Clear">
        <AutocompleteInput optionText="label" />
      </ReferenceInput>
      <TextInput source="phone_number" data-testid="locations-edit-phone" />
      <TextInput source="about" />
      <TextInput source="address_first" data-testid="locations-edit-address-first" />
      <TextInput source="address_second" />
      <TextInput source="address_city" data-testid="locations-edit-city" />
      <TextInput source="address_zip" data-testid="locations-edit-zip" />
      <StateInput source="state_id" />
      <MarketInput />
      <RestrictedInput
        action="update"
        component={SalesAssociateInput}
        source="sales_associate_id"
        emptyText="Clear"
      />
      <RestrictedInput
        action="update"
        component={AccountManagerInput}
        source="account_manager_id"
        emptyText="Clear"
      />
      <RestrictedInput
        action="update"
        component={ClinicalAccountManagerInput}
        source="clinical_account_manager_id"
        emptyText="Clear"
      />
      <RestrictedInput action="update" component={DateTimeInput} source="last_time_job_posted" />
      <TextInput source="detailed_notes" />
      <BooleanInput source="scrubs_provided" />
      <RestrictedInput action="update" component={BooleanInput} source="drug_screening_required" />
      <RestrictedInput
        action="update"
        component={BooleanInput}
        source="require_physical_signature"
      />
      <RestrictedInput
        action="update"
        component={TextInput}
        source="timezone_lookup"
        data-testid="locations-edit-timezone"
      />
      <NumberInput source="billable_orientation_hours" />
      <NumberInput source="non_billable_orientation_burden_hours" />
      <MoneyInput source="parking_reimbursement_cents" />
      <TextInput source="parking_instructions" />
      <BooleanInput source="parking_free" />
      <BooleanInput source="parking_reimbursed" />
      <BooleanInput source="parking_validated" />
      <FormDataConsumer>
        {({ formData }) => {
          const isParkingFree = !!formData.parking_free;

          if (isParkingFree) {
            return <MoneyInput source="parking_cost_cents" format={() => '$0.00'} disabled />;
          }

          return <MoneyInput source="parking_cost_cents" />;
        }}
      </FormDataConsumer>
      <BooleanInput source="allow_per_diem_jobs" />
      <BooleanInput source="allow_assignments" />
      <BooleanInput
        label="Allow Favorite Only Per Diem Jobs"
        source="allow_reserved_for_favorites"
      />
      <RestrictedInput
        action="update"
        component={NumberInput}
        options={{ maximumFractionDigits: 10 }}
        source="coordinates.latitude"
      />
      <RestrictedInput
        action="update"
        component={NumberInput}
        options={{ maximumFractionDigits: 10 }}
        source="coordinates.longitude"
      />
      <RestrictedInput action="update" component={NumberInput} source="cancellation_fee_window" />
      <RestrictedInput
        action="update"
        component={NumberInput}
        source="cancellation_fee_charge_hours"
      />
      <RestrictedInput action="update" component={NumberInput} source="guaranteed_minimum_hours" />
      <RestrictedInput
        action="update"
        component={NumberInput}
        source="facility_cancellation_hours"
      />
      <RestrictedInput
        action="update"
        component={NumberInput}
        source="start_clock_in_early_window_minutes"
        validate={(value, formValues) => {
          if (!isFinite(value)) {
            return 'Must enter a valid number';
          }

          if (value < 0) {
            return 'Cannot be less than 0';
          }

          if (value < formValues.clock_in_early_window) {
            return 'Must be greater than or equal to clock_in_early_window';
          }
        }}
      />
      <RestrictedInput
        action="update"
        component={NumberInput}
        source="clock_in_early_window"
        validate={(value, formValues) => {
          if (!isFinite(value)) {
            return 'Must enter a valid number';
          }

          if (value < 0) {
            return 'Cannot be less than 0';
          }

          if (value > formValues.start_clock_in_early_window_minutes) {
            return 'Must be less than or equal to start_clock_in_early_window_minutes';
          }
        }}
      />
      <RestrictedInput
        label="Maximum Clock Out Period"
        action="update"
        component={NumberInput}
        source="maximum_late_clock_out_grace_period_minutes"
        min={0}
      />
      <RestrictedInput
        label="Approval Free Grace Period"
        action="update"
        component={NumberInput}
        source="approval_free_late_clock_out_grace_period_minutes"
        min={0}
      />
      <RestrictedInput
        label="Common Shift Length Hours"
        action="update"
        component={NumberInput}
        source="common_shift_length_hours"
        min={0}
      />
      <RestrictedInput
        action="update"
        component={BooleanInput}
        label="Allow pro to adjust start/end times"
        source="professional_can_adjust_times"
      />
      <RestrictedInput action="update" component={BooleanInput} source="enable_geo_fence" />
      <RestrictedInput
        action="update"
        component={NumberInput}
        source="geo_fence_radius"
        min={300}
      />
      <RestrictedInput
        action="update"
        component={BooleanInput}
        source="location_confirmation_job_irregularities_enabled"
      />
      <RestrictedInput
        action="update"
        component={BooleanInput}
        label="Require 530 on clock out"
        source="require_upload_on_clock_out"
      />
      <RestrictedInput
        action="update"
        component={BooleanInput}
        label="Require photo on clock in"
        source="require_facility_timecard_system_photo_on_clock_in"
      />
      <RestrictedInput
        action="update"
        component={BooleanInput}
        label="Require photo on clock out"
        source="require_facility_timecard_system_photo_on_clock_out"
      />
      <RestrictedInput
        action="update"
        component={BooleanInput}
        source="ignore_signature_when_creating_meal_break"
      />
      <EnumSelectInput emptyText="None" sort="" source="covid_requirement" />
      <RestrictedInput action="update" component={NumberInput} source="mileage_requirement" />
      <RestrictedInput
        action="update"
        component={NumberInput}
        label="Minimum Years Of Experience"
        source="min_years_of_experience"
      />
      <RestrictedInput
        action="update"
        component={BooleanInput}
        source="allow_weekly_overtime_settings"
      />
      <TextInput source="external_id" label="External ID" />
      <BooleanInput source="pro_pool_enabled" />
      <BooleanInput source="require_shift_confirmation" label="Shift Confirmation Required" />
      <RestrictedInput
        action="update"
        component={BooleanInput}
        source="facility_managed_agency_enabled"
      />
    </SimpleForm>
  </Edit>
);

export default LocationEdit;
