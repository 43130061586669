import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import {
  TextInput,
  NumberInput,
  BooleanInput,
  Edit,
  SimpleForm,
  AutocompleteInput,
} from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const PositionEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="position-edit">
    <SimpleForm>
      <TextInput source="name" data-testid="position-edit-name" />
      <TextInput source="display_name" data-testid="position-edit-display-name" />
      <NumberInput source="sequence" />
      <EnumSelectInput source="kind" />
      <NumberInput
        source="hospital_mark_up"
        format={(v) => v * 100}
        parse={(v) => parseFloat(v) / 100}
        label="Hospital Mark Up %"
      />
      <ReferenceInput reference="QualificationsGroup" source="qualifications_group_id">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
      <BooleanInput source="high_risk" />
      <BooleanInput source="requires_specialty" />
      <TextInput
        source="responsibilities"
        data-testid="position-edit-responsibilities"
        multiline
        fullWidth
      />
      <TextInput source="lead_question" multiline fullWidth />
      <TextInput source="lead_description" multiline fullWidth />
      <TextInput source="requirements_description" multiline fullWidth />
    </SimpleForm>
  </Edit>
);

export default PositionEdit;
