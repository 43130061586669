import React from 'react';
import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';

const WageLogsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <NumberInput label="Hourly Rate (Cents)" source="hourly_rate_cents" />
      <NumberInput label="Position ID" source="position_id" />
      <NumberInput label="Work History ID" source="work_history_id" />
      <TextInput label="Zip Code" source="zip_code" />
      <ProfessionalInput />
      <NumberInput label="Professional ID" source="professional_id" />
    </SimpleForm>
  </Edit>
);

export default WageLogsEdit;
