import React from 'react';

import { Button, Confirm, useRecordContext, useRefresh } from 'react-admin';
import useApi from 'hooks/useApi';
import useDialog from 'hooks/useDialog';

const ReversalButton = () => {
  const api = useApi();
  const { open, openDialog, closeDialog } = useDialog();
  const record = useRecordContext();
  const refresh = useRefresh();

  const onConfirm = async () => {
    await api({
      url: `/v3/admin/payouts/${record.id}/reverse`,
      method: 'POST',
    });
    refresh();
    closeDialog();
  };

  return (
    <>
      <Button onClick={openDialog} label="Admin Reverse" />
      <Confirm
        isOpen={open}
        onClose={closeDialog}
        onConfirm={onConfirm}
        title="Confirm Admin Reversal"
        content={
          'Please ensure any required changes on Stripe are handled manually through their dashboard beforehand.' +
          'Reversing this payout will reset the necessary fields in our system, allowing it to be re-processed in the next billing cycle.'
        }
      />
    </>
  );
};

export default ReversalButton;
