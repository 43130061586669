import * as React from 'react';
import { NumberField, useRecordContext } from 'react-admin';
import _ from 'lodash';

const MoneyField = (props) => {
  const { label, source, currencySource = '', style, sortable, blankIfNull = false, sortBy } = props;
  const record = useRecordContext(props);
  const valueCents = _.get<number | null>(record, source.split('.')) ?? (blankIfNull ? null : 0);
  const currency = record?.[currencySource] ?? 'USD';
  if (valueCents === null && blankIfNull) {
    return null;
  }

  return (
    <NumberField
      data-testid="money-field"
      label={label}
      source={source}
      record={{ ...record, [source]: valueCents / 100 }}
      options={{ style: 'currency', currency }}
      style={style}
      sortable={sortable}
      sortBy={sortBy}
    />
  );
};

export default MoneyField;
