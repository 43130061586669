import React, { useState } from 'react';
import {
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { Dialog } from '@medely/web-components';
import { Button } from '@mui/material';
import DateTimeInput from 'components/DateTimeInput';
import ProfessionalInput from 'components/ProfessionalInput';
import JobInput from 'components/JobInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import useApi from 'hooks/useApi';
import { validateEndsTime } from 'utils/custom-validations';

export const CreateTestJobButton = () => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const notify = useNotify();
  const api = useApi();
  const redirect = useRedirect();

  const showButton =
    process.env.REACT_APP_STAGING === 'true' || process.env.NODE_ENV === 'development';

  if (!showButton || !record) {
    return null;
  }

  const onSuccess = () => {
    notify('Job created successfully', { type: 'success' });
    setOpen(false);
  };

  const handleSubmit = async (values) => {
    const { job_id, ...rest } = values;

    const error = validateEndsTime(rest.ends_time, values);
    if (error) {
      notify(error, { type: 'error' });
      return;
    }

    try {
      const response = await api({
        url: `/v3/admin/development/jobs/${job_id}/clone`,
        method: 'POST',
        data: {
          job: rest,
        },
      });
      const newJobId = response.data.payload.id;
      if (newJobId) {
        onSuccess();
        redirect(`/Job/${newJobId}/show`);
      }
    } catch (e: any) {
      notify(e.response.data.message ?? 'Something went wrong', { type: 'warning' });
    }
  };

  const timezone = record.location.timezone_lookup;

  // TODO: Add validation for the form.
  return (
    <>
      <Button size="small" variant="outlined" color="primary" onClick={() => setOpen(true)}>
        Create Test Job
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <SimpleForm
          toolbar={
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SaveButton label="Submit" />
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </Toolbar>
          }
          onSubmit={handleSubmit}
          record={{
            job_id: record.id,
            professional_id: record.professional_id,
            status: record.status,
            starts_time: record.starts_time,
            ends_time: record.ends_time,
          }}
        >
          <JobInput source="job_id" disabled />
          <ProfessionalInput source="professional_id" />
          <DateTimeInput
            time_zone={timezone}
            label="Starts Time"
            source="starts_time"
            helperText={`Timezone: ${timezone}`}
          />
          <DateTimeInput
            time_zone={timezone}
            label="Ends Time"
            source="ends_time"
            helperText={`Timezone: ${timezone}`}
          />
          <EnumSelectInput label="Status" source="status" />
        </SimpleForm>
      </Dialog>
    </>
  );
};
